import { Col, Row, Space } from 'antd';
import { PrimaryFilledPanel, RowSpacing } from 'component';
import { useAppContext } from 'core';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentClusterSelector, screenListSelector, showOfflineSelector } from '../ScreenStatusMapMdl';
import InitClusterAction from './InitClusterAction';
import LoadSelectionAction from './LoadSelectionAction';
import SaveSelectionAction from './SaveSelectionAction';
import ShareClusterAction from './ShareClusterAction';

const ClusterPanel = () => {
    const screenList = useSelector(screenListSelector);
    const currentCluster = useSelector(currentClusterSelector);
    const [selectedScreenList, setSelectedScreenList] = useState([]);
    const [clusterScreenList, setClusterScreenList] = useState([]);
    const showOffline = useSelector(showOfflineSelector);
    const dateFormat = useAppContext().dateFormat;

    useEffect(() => {
        const x = screenList.filter((screen) => screen.selected);
        setSelectedScreenList(x);
    }, [screenList]);

    useEffect(() => {
        const x = currentCluster.screens?.filter((screen) => showOffline || screen.status === 'online');
        setClusterScreenList(x);
    }, [screenList]);

    if (currentCluster?.name) {
        return (
            <PrimaryFilledPanel label="Select From Cluster">
                <div style={{ color: '#333', fontWeight: '200', fontSize: '18px' }}>Cluster {currentCluster.name}</div>
                <div style={{ color: '#999', fontWeight: '700', fontSize: '12px' }}>
                    <span> updated at </span>
                    <span>{dayjs(currentCluster.updatedAt).format(dateFormat)}</span>
                </div>
                <RowSpacing />
                <Space>
                    {selectedScreenList?.length !== clusterScreenList?.length && <SaveSelectionAction />}
                    <InitClusterAction />
                    <ShareClusterAction />
                </Space>
            </PrimaryFilledPanel>
        );
    } else {
        return (
            <PrimaryFilledPanel label="Select From Cluster">
                <Row style={{ border: '1px solid orange', color: 'orange', fontWeight: '500', margin: '10px 0', borderRadius: '4px', padding: '5px' }}>
                    <Col flex="auto" style={{ padding: '5px 10px' }}>
                        No Cluster Loaded
                    </Col>
                    <Col flex="none">
                        <LoadSelectionAction />
                    </Col>
                </Row>
            </PrimaryFilledPanel>
        );
    }
};

export default ClusterPanel;
