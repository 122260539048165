import { HomeOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import booking from 'asset/image/booking.png';
import content from 'asset/image/content.png';
import doohMap from 'asset/image/doohMap.png';
import inventory from 'asset/image/inventory.png';
import jobs from 'asset/image/jobs.png';
import { Card, Section } from 'component';
import { ModuleDefinition, PageDefinition, useGoToModule, ViewContextProvider } from 'core';

const AdminHomeView = () => {
    const goToModule = useGoToModule();

    const viewMap = () => {
        goToModule('DoohMapModule');
    };

    const viewInventory = () => {
        goToModule('InventoryModule');
    };

    const viewContentModule = () => {
        goToModule('ContentModule');
    };

    const viewBookingModule = () => {
        goToModule('BookingModule');
    };

    const viewJobsModule = () => {
        goToModule('JobsModule');
    };

    return (
        <Section>
            <Row gutter={20}>
                <Col span="4">
                    <Card title="doohMap" image={doohMap} action={viewMap} />
                </Col>
                <Col span="4">
                    <Card title="inventory" image={inventory} action={viewInventory} />
                </Col>
                <Col span="4">
                    <Card title="booking" image={booking} />
                </Col>
                <Col span="4">
                    <Card title="content" image={content} />
                </Col>
                <Col span="4">
                    <Card title="jobs" image={jobs} />
                </Col>
            </Row>
        </Section>
    );
};
const AdminHomePage: PageDefinition = {
    key: 'AdminHomePage',
    path: '/',
    toPath: () => '/',
    icon: <HomeOutlined />,
    menu: '/',
    view: (
        <ViewContextProvider uc="AdminHomeUc">
            <AdminHomeView />
        </ViewContextProvider>
    ),
};
const AdminHomeModule = (subModuleList?: ModuleDefinition[]): ModuleDefinition => {
    return {
        key: 'AdminHomeModule',
        i18nMap: {
            AdminHomePage: 'Home',
            'AdminHomeUc.title': 'Home',
        },
        pageList: [
            AdminHomePage, //
        ],
        subModuleList: subModuleList,
        index: AdminHomePage,
    };
};
export default AdminHomeModule;
