import { BtnProps } from './BaseButton';
import InactifFilledButton from './DisabledFilledButton';
import PrimaryFilledButton from './PrimaryFilledButton';
import WarningFilledButton from './WarningFilledButton';

const FilledButton = (props: BtnProps) => {
    if (props.disabled) {
        return <InactifFilledButton {...props} />;
    } else if (props.color === 'warning') {
        return <WarningFilledButton {...props} />;
    } else {
        return <PrimaryFilledButton {...props} />;
    }
};

export default FilledButton;
