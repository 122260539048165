import { CheckOutlined, EyeOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import { PrimaryFilledPanel } from 'component';
import InTableViewAction from 'component/action/InTableViewAction';
import { STable } from 'component/table/styles';
import { useAppDispatch } from 'core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FindScreenMdl, screenPaginatedListSelector } from '../FindScreenMdl';

const ScreenTable = ({ viewScreen }) => {
    const dispatch = useAppDispatch();
    const screenPaginatedList = useSelector(screenPaginatedListSelector);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });

    const selectScreen = (screen) => {
        dispatch(FindScreenMdl.selectScreen(screen));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
    };

    const nullSafeSort = (a, b, key) => {
        const valA = a[key] ?? ''; // Use empty string if null or undefined
        const valB = b[key] ?? '';
        return valA.localeCompare(valB);
    };
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => nullSafeSort(a, b, 'name'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => nullSafeSort(a, b, 'status'),
        },
        {
            title: 'Zipcode',
            dataIndex: 'zipcode',
            key: 'zipcode',
            sorter: (a, b) => nullSafeSort(a, b, 'zipcode'),
        },
        {
            title: 'Status Last',
            dataIndex: 'status_last',
            key: 'status_last',
            sorter: (a, b) => nullSafeSort(a, b, 'status_last'),
            width: 200,
        },
        {
            title: 'View Screen',
            dataIndex: 'viewScreen',
            key: 'viewScreen',
            render: (text, record) => <InTableViewAction name="viewScreen" icon={<EyeOutlined />} action={() => viewScreen(record)} />,
        },
        {
            title: 'Select Screen',
            dataIndex: 'selectScreen',
            key: 'selectScreen',
            render: (text, record) => <InTableViewAction name="selectScreen" icon={<CheckOutlined />} action={() => selectScreen(record)} />,
        },
    ];

    const length = () => {
        return screenPaginatedList.list ? screenPaginatedList.list?.length : 0;
    };
    return (
        <Badge.Ribbon text={length()} color="red">
            <PrimaryFilledPanel title="screen.resultList">
                <STable
                    dataSource={screenPaginatedList.list}
                    columns={columns}
                    pagination={{
                        ...pagination,
                        total: screenPaginatedList.list?.length,
                        showSizeChanger: false, // Disable changing page size
                    }}
                    onChange={handleTableChange}
                    bordered //
                />
            </PrimaryFilledPanel>
        </Badge.Ribbon>
    );
};

export default ScreenTable;
