import { action } from 'core';
import ClusterService from 'model/cluster/ClusterService';
import { IScreen } from 'model/screen/ScreenDataModel';
import ScreenService from 'model/screen/ScreenService';
import { ScreenStatusMapReq, ScreenStatusMapRes } from './ScreenStatusMapMdl';


const listScreenForMapImpl = async (request: ScreenStatusMapReq, result: ScreenStatusMapRes, thunkAPI) => {
    result.screenList = await ScreenService.listScreenForMap();
};

const listClusterImpl = async (request: ScreenStatusMapReq, result: ScreenStatusMapRes, thunkAPI) => {
    result.clusterList = await ClusterService.list();
};


const addClusterImpl = async (request: ScreenStatusMapReq, result: ScreenStatusMapRes, thunkAPI) => {
    const { screenStatusMapMdl } = thunkAPI.getState() as any;
    result.cluster = await ClusterService.add({ name: request.name, screens: screenStatusMapMdl.screenList.filter((s) => s.selected).map((s) => s.id) });
};

const saveClusterImpl = async (request: ScreenStatusMapReq, result: ScreenStatusMapRes, thunkAPI) => {
    const { screenStatusMapMdl } = thunkAPI.getState() as any;
    const oldCluster = await ClusterService.get(request.clusterId);
    /*
    const oldList: IScreen[] = oldCluster.screens;
    const newList: IScreen[] = screenStatusMapMdl.screenList.filter(screen => screen.selected === true);

    const oldIds = new Set(oldList.map(screen => screen.id));
    const newIds = new Set(newList.map(screen => screen.id));

    const idsToAdd = newList.filter(screen => !oldIds.has(screen.id)).map(screen => screen.id);

    const idsToRemove = oldList.filter(screen => !newIds.has(screen.id)).map(screen => screen.id);

    if (idsToAdd.length > 0) {
        await ClusterService.addScreen(oldCluster, idsToAdd);
    }
    if (idsToRemove.length > 0) {
        await ClusterService.removeScreen(oldCluster, idsToRemove);
    }
        */
    const updatedList: IScreen[] = screenStatusMapMdl.screenList.filter(screen => screen.selected === true);
    const idsToUpdate = updatedList.map(screen => screen.id);
    await ClusterService.updateScreen(oldCluster, idsToUpdate);
};

const getClusterImpl = async (request: ScreenStatusMapReq, result: ScreenStatusMapRes, thunkAPI) => {
    result.cluster = await ClusterService.get(request.clusterId);
};

const ScreenStatusMapCtrl = {
    listScreenForMap: action<ScreenStatusMapReq, ScreenStatusMapRes>(listScreenForMapImpl, 'ScreenStatusMapCtrl/listScreenForMap'),
    listCluster: action<ScreenStatusMapReq, ScreenStatusMapRes>(listClusterImpl, 'ScreenStatusMapCtrl/listCluster'),
    addCluster: action<ScreenStatusMapReq, ScreenStatusMapRes>(addClusterImpl, 'ScreenStatusMapCtrl/addCluster'),
    saveCluster: action<ScreenStatusMapReq, ScreenStatusMapRes>(saveClusterImpl, 'ScreenStatusMapCtrl/saveCluster'),
    getCluster: action<ScreenStatusMapReq, ScreenStatusMapRes>(getClusterImpl, 'ScreenStatusMapCtrl/getCluster'),
};

export default ScreenStatusMapCtrl;
