import { Button, Tooltip } from 'antd';
import { PageDefinition, useGoToPage, useI18n } from 'core';

export type BtnProps = {
    name?: string | null;
    context?: string;
    action?: (event) => void | null;
    page?: PageDefinition | null;
    modele?: any | null;
    label?: string | null;
    icon?: React.ReactNode | null;
    disabled?: string | null;
    visible?: boolean;
    rid?: string | null;
    toolTip?: string | null;
    width?: number | string | null;
    className?: string | null;
    color?: 'primary' | 'warning';
    shape?: 'outlined' | 'filled' | 'link';
    size?: 'small' | 'middle' | 'large';
};

const BaseBouton = ({
    name = null, //
    context = null,
    action = null,
    page = null,
    modele = null,
    label = null,
    icon = null,
    disabled = null,
    visible = true,
    rid = null,
    toolTip = null,
    width = null,
    className = '',
    size = null,
}: BtnProps) => {
    const { i18n } = useI18n();
    const gtp = useGoToPage();
    const executeOnClick = (event) => {
        if (!disabled && action) {
            action(event);
        } else if (!disabled && page) {
            gtp(page, modele);
        }
    };
    if (visible) {
        return (
            <Tooltip placement="top" title={toolTip || disabled}>
                <Button //
                    id={`bouton${context ? '_' + context : ''}_${name}`}
                    onClick={executeOnClick}
                    icon={icon}
                    size={size}
                    loading={rid !== null}
                    className={className}
                    style={{ fontWeight: 500, width }}
                    type="default"
                    disabled={disabled ? true : false}
                >
                    {label ? label : name ? i18n(name) : ''}
                </Button>
            </Tooltip>
        );
    }
};

export default BaseBouton;
