import { action } from 'core';
import MetadataService from 'model/metadata/MetadataService';
import ScreenService from 'model/screen/ScreenService';
import { FindScreenReq, FindScreenRes } from './FindScreenMdl';



const changePageImpl = async (request: FindScreenReq, result: FindScreenRes, thunkAPI) => {
    const { findScreenMdl } = thunkAPI.getState() as any;
    result.screenPaginatedList = await ScreenService.find(findScreenMdl.query, request.currentPage);
};

const findScreenImpl = async (request: FindScreenReq, result: FindScreenRes, thunkAPI) => {
    const query = {
        criteriaIds: request.dataForm.criteriaIds,
        name: request.dataForm.name,
        zipcode: request.dataForm.zipcode,
        playerFilters: Object.entries(request.dataForm)
            .filter(([key]) => !['name', 'zipcode', 'criteriaIds'].includes(key))
            .map(([key, value]) => ({
                key,
                value
            }))
    };

    result.screenPaginatedList = await ScreenService.find(query, request.currentPage);
};
const getScreenImpl = async (request: FindScreenReq, result: FindScreenRes, thunkAPI) => {
    result.screen = await ScreenService.get(request.screenId);
};

const initImpl = async (request: FindScreenReq, result: FindScreenRes, thunkAPI) => {
    result.metadata = await MetadataService.get();
};

const exportScreenImpl = async (request: FindScreenReq, result: FindScreenRes, thunkAPI) => {
    const { findScreenMdl } = thunkAPI.getState() as any;
    const screenIds = findScreenMdl.selectedScreenList.map((screen) => screen.id);
    await ScreenService.exportScreen({ ...request.dataForm, screenIds });
};

const FindScreenCtrl = {
    changePage: action<FindScreenReq, FindScreenRes>(changePageImpl, 'FindScreenCtrlCtrl/changePage'),
    findScreen: action<FindScreenReq, FindScreenRes>(findScreenImpl, 'FindScreenCtrlCtrl/findScreen'),
    getScreen: action<FindScreenReq, FindScreenRes>(getScreenImpl, 'FindScreenCtrlCtrl/getScreen'),
    init: action<FindScreenReq, FindScreenRes>(initImpl, 'FindScreenCtrlCtrl/init'),
    exportScreen: action<FindScreenReq, FindScreenRes>(exportScreenImpl, 'FindScreenCtrlCtrl/exportScreen'),
};

export default FindScreenCtrl;
