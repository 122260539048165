import axios from 'axios';
import { API_URL } from 'common/config/constants.config';
import { ICluster } from './ClusterDataModel';

const list = async (): Promise<ICluster[]> => {
    const response = await axios.get(`${API_URL}/clusters`);
    return response.data;
};

const get = async (id: string): Promise<ICluster> => {
    const response = await axios.get(`${API_URL}/clusters/${id}`);
    const c = response.data;
    const screens = c.Screens.map((s) => { return { ...s, lat: +s.lat, lng: +s.lng }; });
    return { ...c, screens };
};

const add = async (cluster: ICluster): Promise<ICluster> => {
    const response = await axios.post(`${API_URL}/clusters`, cluster);
    return response.data;
};

const addScreen = async (cluster: ICluster, screenIdList: string[]): Promise<void> => {
    const url = `${API_URL}/clusters/${cluster.id}/screens/`;
    const data = { action: 'add', screenIds: screenIdList.join(',') };
    await axios.post(url, data);
};

const removeScreen = async (cluster: ICluster, screenIdList: string[]): Promise<void> => {
    const url = `${API_URL}/clusters/${cluster.id}/screens/`;
    const data = { action: 'remove', screenIds: screenIdList.join(',') };
    await axios.post(url, data);
};

const updateScreen = async (cluster: ICluster, screenIdList: string[]): Promise<void> => {
    const url = `${API_URL}/clusters/${cluster.id}/screens/`;
    const data = { action: 'update', screenIds: screenIdList.join(',') };
    await axios.post(url, data);
};
const ClusterService = {
    list,
    get,
    add,
    addScreen,
    removeScreen,
    updateScreen,
};

export default ClusterService;