

const ALL = [
    'viewScreen',
    'selectScreen',
    'exportScreen',
];
const ACL_ADMIN = [...ALL,];
const ACL_USER = [...ALL,];

const permissionMap = {
    ADMIN: ACL_ADMIN,
    USER: ACL_USER,
};

export default permissionMap;
