import { Badge } from 'antd';
import { PrimaryFilledPanel, RowSpacing } from 'component';
import FilledButton from 'component/button/FilledButton';
import Column from 'component/table/Column';
import Table from 'component/table/Table';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectedScreenListSeclector } from '../FindScreenMdl';
import ExportScreenDialog from './ExportScreenDialog';

const SelectedScreenTable = ({ viewScreen }) => {
    const selectedScreenList = useSelector(selectedScreenListSeclector);
    const [exportScreenVisible, setExportScreenVisible] = useState(false);

    return (
        <Badge.Ribbon text={selectedScreenList?.length} color="red">
            <PrimaryFilledPanel title="screen.selectedList">
                <Table data={selectedScreenList}>
                    <Column name="id" />
                    <Column name="name" />
                    <Column name="status" />
                    <Column name="zipcode" />
                </Table>
                <RowSpacing />
                <div style={{ textAlign: 'right' }}>
                    <FilledButton action={() => setExportScreenVisible(true)} name="exportScreen" disabled={selectedScreenList?.length > 0 ? null : 'No selected screens'} />
                </div>
                <ExportScreenDialog visible={exportScreenVisible} setVisible={setExportScreenVisible} />
            </PrimaryFilledPanel>
        </Badge.Ribbon>
    );
};

export default SelectedScreenTable;
