import axios from 'axios';
import { API_URL } from 'common/config/constants.config';
import { PaginatedList } from 'core/pagination/PaginationDataModel';
import { IScreen } from './ScreenDataModel';
import ScreenMapper from './ScreenMapper';


const list = async (currentPage): Promise<PaginatedList<IScreen>> => {
    const response = await axios.get(`${API_URL}/screens?page=${currentPage}`);
    const list = response.data?.rows?.map((screen: IScreen) => {
        return { ...screen, lat: +screen.lat, lng: +screen.lng };
    });
    return { list, pagination: { currentPage, rowPerPage: 100, totalRow: response.data?.count } };
};

const listScreenForMap = async (): Promise<IScreen[]> => {
    const listPaginaged = await list(-1);
    return ScreenMapper.applyMarkerOffset(listPaginaged.list);
};


const get = async (screenId): Promise<IScreen> => {
    const response = await axios.get(`${API_URL}/screens/${screenId}`);
    return response.data;
};

const find = async (screenQuery, currentPage: number) => {
    const response = await axios.post(`${API_URL}/screens`, screenQuery);
    const list = response.data?.rows?.map((screen: IScreen) => {
        return { ...screen, lat: +screen.lat, lng: +screen.lng };
    });
    return { list, pagination: { currentPage, rowPerPage: 100, totalRow: response.data?.count } };
};

const exportScreen = async (screenQuery) => {
    const response = await axios.post(`${API_URL}/screens-export`, screenQuery);
};

const ScreenService = {
    list,
    listScreenForMap,
    get,
    find,
    exportScreen,
};

export default ScreenService;