import DoohMapModule from 'module/doohMap/definition/DoohMapModule';
import InventoryModule from 'module/inventory/definition/InventoryModule';
import AdminHomeModule from './home';

const AdminModuleList = [
    AdminHomeModule(), //
    DoohMapModule(),
    InventoryModule(),
    // CampaignModule(),
    // ContentModule(),
    // JobsModule(),
];
export default AdminModuleList;
