import { DeleteFilled, DownOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Form, Row, Select, Space, TreeSelect } from 'antd';
import { MenuProps } from 'antd/lib';
import { ChampTexte, Formulaire } from 'component';
import IconButton from 'component/button/IconButton';
import PrimaryFilledButton from 'component/button/PrimaryFilledButton';
import Panel from 'component/container/Panel';
import { useAppDispatch, useExecute, util } from 'core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FindScreenCtrl from '../FindScreenCtrl';
import { FindScreenMdl, metadataSelector } from '../FindScreenMdl';

const { Option } = Select;

const ScreenFilter = () => {
    const dispatch = useAppDispatch();
    const { execute } = useExecute();
    const [form] = Form.useForm();
    const [edit, setEdit] = useState(true);
    const metadata = useSelector(metadataSelector);
    const [value, setValue] = useState<string>();
    const [treeData, setTreeData] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [keyList, setKeyList] = useState<MenuProps['items']>([]);
    const [formItems, setFormItems] = useState<{ key: string }[]>([]);

    const onClick: MenuProps['onClick'] = ({ key }) => {
        if (!formItems.some((item) => item.key === key)) {
            setFormItems([...formItems, { key }]);
        }
    };

    useEffect(() => {
        dispatch(FindScreenMdl.init());
    }, []);

    const convertTree = (node: any) => {
        if (node.children?.length > 0) {
            return {
                title: node.name,
                value: '' + node.id,
                children: node.children?.map(convertTree),
            };
        } else {
            return {
                title: node.name,
                value: '' + node.id,
                isLeaf: true,
            };
        }
    };

    useEffect(() => {
        if (metadata?.criteriaTree?.children?.length > 0) {
            const newTree = convertTree(metadata.criteriaTree);
            const a = [];
            a.push(newTree);
            setTreeData(a);
        }
        if (metadata?.playerFiltersList) {
            const kl = metadata?.playerFiltersList.map((item) => {
                return { key: item.key, label: item.key };
            });
            setKeyList(kl);
        }
    }, [metadata]);

    const collectLeafNodes = (treeData, selectedKeys) => {
        const leafNodes = [];

        const findLeaves = (node) => {
            // If the node has children, recursively check its children
            if (node.children && node.children.length > 0) {
                node.children.forEach(findLeaves);
            } else {
                // It's a leaf node, add it to the leafNodes array
                leafNodes.push(node.value);
            }
        };

        // For each selected key, check the corresponding node in treeData
        const traverseTree = (nodes) => {
            nodes.forEach((node) => {
                // If the node itself is selected
                if (selectedKeys.includes(node.value)) {
                    // Collect all the leaf nodes under the node (if any)
                    findLeaves(node);
                }
                // Continue to traverse through the rest of the tree
                if (node.children && node.children.length > 0) {
                    traverseTree(node.children);
                }
            });
        };

        // Start traversing the tree to process selected keys
        traverseTree(treeData);

        return leafNodes;
    };

    const find = () => {
        const formValues = util.removeNonSerialisable(form.getFieldsValue());
        const selectedCriteria = formValues.criteriaIds || [];
        const leafNodes = collectLeafNodes(treeData, selectedCriteria);
        formValues.criteriaIds = leafNodes;
        execute(FindScreenCtrl.findScreen, {
            dataForm: formValues,
            currentPage: 1,
        });

        setEdit(false);
    };

    const editQuery = () => {
        setEdit(true);
    };

    const onChange = (newValue: string) => {
        console.log(newValue);
        setValue(newValue);
    };

    const removeFormItem = (keyToRemove: string) => {
        setFormItems(formItems.filter((item) => item.key !== keyToRemove));
        form.resetFields([keyToRemove]);
    };

    return (
        <div>
            {edit && metadata?.playerFiltersList && (
                <div>
                    <Formulaire form={form} nombreColonne={2}>
                        <ChampTexte nom="name" />
                        <ChampTexte nom="zipcode" />
                    </Formulaire>
                    <Row gutter={20}>
                        <Col span="12">
                            <Panel label="Dynamic Filter" type="simple">
                                <Form layout="vertical" form={form}>
                                    {formItems.map((item) => (
                                        <Row key={item.key}>
                                            <Col flex="auto">
                                                <Form.Item name={item.key} label={item.key}>
                                                    <Select mode="multiple">
                                                        {metadata?.playerFiltersList
                                                            .find((dataItem) => dataItem.key === item.key)
                                                            ?.values.map((value) => (
                                                                <Option key={value} value={value}>
                                                                    {value}
                                                                </Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col flex="none" style={{ display: 'flex', alignItems: 'end' }}>
                                                <IconButton icon={<DeleteFilled />} action={() => removeFormItem(item.key)} />
                                            </Col>
                                        </Row>
                                    ))}
                                    <div style={{ margin: '20px 0' }}>
                                        <Dropdown menu={{ items: keyList, onClick }}>
                                            <Button onClick={(e) => e.preventDefault()}>
                                                <Space>
                                                    Add Filter
                                                    <DownOutlined />
                                                </Space>
                                            </Button>
                                        </Dropdown>
                                    </div>
                                </Form>
                            </Panel>
                        </Col>
                        <Col span="12">
                            <Panel label="Criteria Filter" type="simple">
                                <Form layout="vertical" form={form}>
                                    <Form.Item name="criteriaIds" label="Criteria List">
                                        <TreeSelect //
                                            showSearch
                                            style={{ width: '100%' }}
                                            value={value}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            placeholder="Please select"
                                            allowClear
                                            multiple
                                            filterTreeNode
                                            treeNodeFilterProp="title"
                                            treeDefaultExpandAll
                                            treeCheckable={true}
                                            showCheckedStrategy="SHOW_PARENT"
                                            onChange={onChange}
                                            treeData={treeData}
                                        />
                                    </Form.Item>
                                </Form>
                            </Panel>
                        </Col>
                    </Row>
                </div>
            )}
            <div style={{ textAlign: 'right' }}>
                {edit && <PrimaryFilledButton icon={<SearchOutlined />} name="find" action={find} />}
                {!edit && <PrimaryFilledButton icon={<EditOutlined />} name="editQuery" action={editQuery} />}
            </div>
        </div>
    );
};

export default ScreenFilter;
